"use client";
import fetchJson from "@/lib/fetchJson";
import { useState } from "react";
import { capitalize } from "@/lib/util";
import { Button, Flex, IconButton, Text, TextField } from "@radix-ui/themes";
import { IconEdit } from "@tabler/icons-react";
import * as Dialog from "@/components/CustomComponents/Dialog"

interface Props {
  mutate: any;
  creator: Creator;
  price: CreatorPrice;
  budget: CreatorBudget;
}
export default function NewEditBudget({ mutate, creator, budget, price }: Props) {
  const [count, setCount] = useState(budget.budget.toString() || "");

  const update = async () => {
    await fetchJson(`/creator/budget/${budget.id}`, {
      method: "POST",
      body: JSON.stringify({
        count: parseInt(count),
        price: price.id,
        campaign: budget.campaignId,
      }),
    });
    mutate();
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <IconButton variant="outline">
          <IconEdit className="icon-accent"/>
        </IconButton> 
      </Dialog.Trigger>
    <Dialog.Portal>
      <Dialog.Content style={{maxWidth:"450px"}}>
        <Dialog.Title>Edit Budget</Dialog.Title> 
   

        <Flex direction="column" gap="3">
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Creator
          </Text>
          {creator.name}
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Platform
          </Text>
          @{price.username} - {capitalize(price.platform)}
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Number of Posts
          </Text>
          <TextField.Root
              value={count}
              onChange={(e) => setCount(e.target.value)}
            />
        </label>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button 
              variant="soft" 
              color="gray"
              onClick={() => {
                setCount("");
              }}>
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button 
            onClick={() => update()}>
              Save
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
