import fetchJson from "@/lib/fetchJson";
import useSWR from "swr";

interface CreatorQuery {
  creator: Creator;
  budgets: CreatorBudget[];
  posts: Post[];
}

interface PostQuery {
  posts: Post[]
}

export const useCreators = () => useSWR<Creator[]>(`/creator`, fetchJson);

export const useCreator = (id: string) =>
  useSWR<CreatorQuery>(`/creator/${id}`, fetchJson);

  export const useCreatorPosts = (id: string) =>
  useSWR<PostQuery>(`/creator/${id}/posts`, fetchJson);

  export const getCreatorPosts = async (id: string) =>
  fetchJson(`/creator/${id}/posts`, {
    method: "GET",
  });

export const useCreatorTags = () =>
  useSWR<CreatorTag[]>("/creator/tags", fetchJson);

export const useCreatorThemes = () =>
  useSWR<CreatorTheme[]>("/creator/theme", fetchJson);

export const deleteTag = async (id: string) =>
  fetchJson(`/creator/tags/${id}`, {
    method: "DELETE",
  });

export const deleteTheme = async (id: string) =>
  fetchJson(`/creator/theme/${id}`, {
    method: "DELETE",
  });

  export const deleteVibe = async (id: string) =>
  fetchJson(`/creator/vibe/${id}`, {
    method: "DELETE",
  });

export const deleteBudget = (id: string) =>
  fetchJson(`/creator/budget/${id}`, {
    method: "DELETE",
  });

export const updateCreator = async (id: string, data: any) =>
  fetchJson(`/creator/${id}`, {
    method: "POST",
    body: JSON.stringify(data),
  });
