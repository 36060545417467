"use client";
import fetchJson from "@/lib/fetchJson";
import { useEffect, useState } from "react";
import useSWR, { KeyedMutator } from "swr";
import { capitalize } from "@/lib/util";
import { Button, Flex, IconButton, Text, TextField } from "@radix-ui/themes";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import * as Dialog from "@/components/CustomComponents/Dialog"
import ClearableSelect from "@/components/ui/ClearableSelect";
import Search from "@/components/CustomComponents/NewSearch";
import { useAllCampaigns } from "@/services/campaign";

interface AddBudgetProps {
  mutate: () => void,
  campaignId?: any,
  defaultCreator?: Creator,
  mobile?: boolean,
}

export default function NewAddBudget({
  mutate,
  campaignId,
  defaultCreator,
  mobile,
}: AddBudgetProps) {
  const [count, setCount] = useState("");
  const [price, setPrice] = useState("");
  const { data } = useSWR<Creator[]>("/creator", fetchJson);
  const [selectedCreator, setSelectedCreator] = useState(defaultCreator || null);
  const [selectedCampaignId, setSelectedCampaignId] = useState(campaignId || null)
  const [creatorData, setCreatorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState([])
  const { data: campaigns } = useAllCampaigns()

  const handleSetCreator = async (newCreatorData) => {
    const creator = await fetchJson(`/creator/${newCreatorData.value}`, {
      method: "GET"
    })
    console.log("creatorData", creator)
    setCreatorData(creator?.creator)
  }

  useEffect(() => {
    console.log("defaultCreator", defaultCreator)
    if (defaultCreator && defaultCreator?.id) {
      handleSetCreator({ value: defaultCreator.id })
    }
  }, [defaultCreator])

  const add = async () => {
    const unparsable = selectedPrice.filter(p => p.price === null || p.count < 0)
    if (unparsable.length > 0) {
      alert("Please fill in all the fields")
      return
    } else {
      setLoading(true)
      for (const { price, count } of selectedPrice) {
        console.log("ADDING", count, price, campaignId)
        await fetchJson(`/creator/budget`, {
          method: "POST",
          body: JSON.stringify({
            count: parseInt(count),
            price: price,
            campaign: selectedCampaignId,
          }),
        });
        setLoading(false)
        setCount("");
        setPrice("");
        setSelectedCampaignId(campaignId || null)
        setSelectedCreator(defaultCreator);
        setSelectedPrice([]);
        mutate();
      }
    }


  };

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        {mobile ?
          <IconButton>
            <IconPlus className="icon-accent" />
          </IconButton>
          : <Button>
            <IconPlus className="icon-accent" />
            Add Budget
          </Button>}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Content style={{ maxWidth: "450px" }}>
          <Dialog.Title>Add Budget</Dialog.Title>
          {!campaignId && <Search
            def={selectedCampaignId || null}
            data={(campaigns || []).map((i) => ({ value: i.id, label: i.name }))}
            onChange={(e) => {
              setSelectedCampaignId(e.value)
            }}
            prompt="Enter campaign name"
            placeholder="Search campaigns..."
            width={"100%"}
          />}
          <Flex direction="column" gap="3">
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                Creator
              </Text>
              {defaultCreator ? <Text size="2">{creatorData?.name}</Text> : <Search
                def={selectedCreator || null}
                data={(data || []).map((i) => ({ value: i.id, label: i.name }))}
                onChange={(e) => {
                  setSelectedCreator(e)
                  handleSetCreator(e)
                }}
                prompt="Enter creator name"
                placeholder="Search creator..."
                width={"100%"}
              />}
            </label>
            {selectedPrice.map(({ price, count }, i) => {
              return (<Flex direction="row" gap="1" justify={"center"} align={"end"}>
                <Flex style={{ width: "70%" }}>
                  <label style={{ width: "100%" }}>
                    {i === 0 && <Text as="div" size="2" mb="1" weight="bold">
                      Platform
                    </Text>}
                    <ClearableSelect
                      placeholder="Select platform..."
                      options={(creatorData?.prices || []).map((i: any) => ({
                        value: i.id,
                        label: `@${i.username} (${capitalize(i.platform)})`,
                      }))}
                      value={price}
                      onValueChange={newPrice => {
                        selectedPrice[i].price = newPrice
                        setSelectedPrice([...selectedPrice])
                      }} />
                  </label>
                </Flex>
                <Flex>
                  <label>
                    {i === 0 && <Text as="div" size="2" mb="1" weight="bold">
                      Number of Posts
                    </Text>}
                    <TextField.Root
                      value={count}
                      onChange={(e) => {
                        selectedPrice[i].count = e.target.value
                        setSelectedPrice([...selectedPrice])
                      }}
                      variant="soft"
                    />
                  </label>
                </Flex>
                <Flex>
                  <IconButton variant="ghost" onClick={() => {
                    selectedPrice.splice(i, 1)
                    setSelectedPrice([...selectedPrice])

                  }}>
                    <IconTrash className="icon-accent" />
                  </IconButton>
                </Flex>
              </Flex>)
            })}

            <Button disabled={!selectedCreator} onClick={() => {
              setSelectedPrice(prev => [...prev, { price: null, count: 0 }])
            }}>Add Platform</Button>
          </Flex>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button
                variant="soft"
                color="gray"
                onClick={() => {
                  setCount("");
                  setPrice("");
                  setSelectedCreator(undefined);
                  setSelectedPrice([])
                }}>
                Cancel
              </Button>
            </Dialog.Close>
            <Dialog.Close>
              <Button
                onClick={() => add()}
                loading={loading}
              >
                Save
              </Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
